import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { getToken } from '../../utils';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const AddPropertyExpense = ({ fetchAdminHelpers }) => {
    const navigate = useNavigate();
    const [investment_property, setInvestmentProperty] = useState({
        property_name: '',
        address: '',
        purchase_date: '',
        purchase_price: '',
        is_land_contract: '',
        picture_url: '',
    });
    const [setError] = useState(null);

    const handleInvestmentPropertyChange = (e) => {
        const { name, value } = e.target;
        setInvestmentProperty({
            ...investment_property,
            [name]: value
        });
    };

    const handleInvestmentPropertySubmit = async (e) => {
        e.preventDefault();

        const { property_name, address, purchase_date, purchase_price, is_land_contract, picture_url } = investment_property;
        if (!property_name || !address || !purchase_date || !purchase_price || !is_land_contract || !picture_url) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/add_investment_property`,
                investment_property,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`New property added successfully`);

            fetchAdminHelpers();

            setInvestmentProperty({
                property_name: '',
                address: '',
                purchase_date: '',
                purchase_price: '',
                is_land_contract: '',
                picture_url: '',
            });
        } catch (error) {
            setError('Error adding property:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Investment Property</p>
                <form onSubmit={handleInvestmentPropertySubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <input
                            type="text"
                            id="property_name"
                            name="property_name"
                            className="form-control"
                            value={investment_property.property_name}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="address">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                            value={investment_property.address}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="date"
                            id="purchase_date"
                            name="purchase_date"
                            className="form-control"
                            value={investment_property.purchase_date}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="purchase_price">Purchase Price:</label>
                        <input
                            type="number"
                            id="purchase_price"
                            name="purchase_price"
                            className="form-control"
                            value={investment_property.purchase_price}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="is_land_contract">Is Land Contract:</label>
                        <select
                            id="is_land_contract"
                            name="is_land_contract"
                            className="form-control text-font"
                            value={investment_property.is_land_contract}
                            onChange={handleInvestmentPropertyChange}
                            required
                        >
                            <option value="">Is Land Contract?</option>
                            <option value="True">True</option>
                            <option value="False">False</option>
                        </select>
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="picture_url">Picture URL:</label>
                        <input
                            type="text"
                            id="picture_url"
                            name="picture_url"
                            className="form-control"
                            value={investment_property.picture_url}
                            onChange={handleInvestmentPropertyChange}
                            required
                        />
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Add Investment Property</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddPropertyExpense;
