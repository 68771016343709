import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddLandContractPayment = ({ landContractProperties, lorenProperties }) => {
    const navigate = useNavigate();
    const [lc_payment, setLcPayment] = useState({
        payment_date: '',
        property_name: '',
        loren_name: '',
        description: '',
        amount: '',
    });
    const [setError] = useState(null);

    const handleLcPaymentChange = (e) => {
        const { name, value } = e.target;
        setLcPayment({
            ...lc_payment,
            [name]: value
        });
    };

    const handleLcPaymentSubmit = async (e) => {
        e.preventDefault();

        const { payment_date, property_name, loren_name, description, amount } = lc_payment;
        if (!payment_date || !property_name || !loren_name || !description || !amount) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/record_lc_payment`,
                lc_payment,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`Land contract payment recorded successfully`);

            setLcPayment({
                payment_date: '',
                property_name: '',
                loren_name: '',
                description: '',
                amount: '',
            });
        } catch (error) {
            setError('Error adding payment:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Land Contract Payment</p>
                <form onSubmit={handleLcPaymentSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="payment_date">Payment Date:</label>
                        <input
                            type="date"
                            id="payment_date"
                            name="payment_date"
                            className="form-control"
                            value={lc_payment.payment_date}
                            onChange={handleLcPaymentChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="description">Description:</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            className="form-control"
                            value={lc_payment.description}
                            onChange={handleLcPaymentChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="amount">Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={lc_payment.amount}
                            onChange={handleLcPaymentChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <select
                            id="property_name"
                            name="property_name"
                            className="form-control text-font"
                            value={lc_payment.property_name}
                            onChange={handleLcPaymentChange}
                            required
                        >
                            <option value="">Select Property</option>
                            {landContractProperties.map((property_name, index) => (
                                <option key={index} value={property_name}>{property_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="loren_name">Loren Name:</label>
                        <select
                            id="loren_name"
                            name="loren_name"
                            className="form-control text-font"
                            value={lc_payment.loren_name}
                            onChange={handleLcPaymentChange}
                            required
                        >
                            <option value="">Select Loren Name</option>
                            {lorenProperties.map((loren_name, index) => (
                                <option key={index} value={loren_name}>{loren_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="text-center"> { }
                        <button type="submit" className="btn btn-primary">Add Payment</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddLandContractPayment;
