import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddInvestor = ({ fetchAdminHelpers }) => {
    const navigate = useNavigate();
    const [investor, setInvestor] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        ssn: '',
        fee_percentage: '',
    });
    const [setError] = useState(null);

    const handleInvestorChange = (e) => {
        const { name, value } = e.target;
        setInvestor({
            ...investor,
            [name]: value
        });
    };

    const handleInvestorSubmit = async (e) => {
        e.preventDefault();

        const { name, email, phone, address, ssn, fee_percentage } = investor;
        if (!name || !email || !phone || !address || !ssn || !fee_percentage) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            const response = await axios.post(`${apiUrl}/admin/add_investor`,
                investor,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`New investor added successfully, login secret is: ${response.data.secret}`);

            fetchAdminHelpers();

            setInvestor({
                name: '',
                email: '',
                phone: '',
                address: '',
                ssn: '',
                fee_percentage: ''
            });
        } catch (error) {
            setError('Error adding investor:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Investor</p>
                <form onSubmit={handleInvestorSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            value={investor.name}
                            onChange={handleInvestorChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="email">Email:</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            className="form-control"
                            value={investor.email}
                            onChange={handleInvestorChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="phone">Phone:</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            className="form-control"
                            value={investor.phone}
                            onChange={handleInvestorChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="address">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                            value={investor.address}
                            onChange={handleInvestorChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="fee_percentage">Fee Percentage:</label>
                        <select
                            id="fee_percentage"
                            name="fee_percentage"
                            className="form-control text-font"
                            value={investor.fee_percentage}
                            onChange={handleInvestorChange}
                            required
                        >
                            <option value="">Select Fee Percentage</option>
                            <option value="0.0">0</option>
                            <option value="0.1">10%</option>
                            <option value="0.2">20%</option>
                            <option value="0.3">30%</option>
                        </select>
                    </div>
                    <div className="text-center"> { }
                        <button type="submit" className="btn btn-primary">Add Investor</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddInvestor;
