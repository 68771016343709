import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddRentalDeposit = ({ rentalProperties, lorenProperties }) => {
    const navigate = useNavigate();
    const [rental_deposit, setRentalDeposit] = useState({
        property_name: '',
        amount: '',
        date: '',
        notes: '',
        loren_name: '',
    });
    const [setError] = useState(null);

    const handleRentalDepositChange = (e) => {
        const { name, value } = e.target;
        setRentalDeposit({
            ...rental_deposit,
            [name]: value
        });
    };

    const handleRentalDepositSubmit = async (e) => {
        e.preventDefault();

        const { property_name, amount, date, notes } = rental_deposit;
        if (!property_name || !amount || !date || !notes) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/add_rental_deposit`,
                rental_deposit,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`New deposit added successfully`);

            setRentalDeposit({
                property_name: '',
                amount: '',
                date: '',
                notes: '',
                loren_name: '',
            });
        } catch (error) {
            setError('Error adding deposit:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Rental Deposit</p>
                <form onSubmit={handleRentalDepositSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property Name:</label>
                        <select
                            id="property_name"
                            name="property_name"
                            className="form-control text-font"
                            value={rental_deposit.property_name}
                            onChange={handleRentalDepositChange}
                            required
                        >
                            <option value="">Select Property</option>
                            {rentalProperties.map((property_name, index) => (
                                <option key={index} value={property_name}>{property_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="amount">Deposit Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={rental_deposit.amount}
                            onChange={handleRentalDepositChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="date">Deposit Date:</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                            value={rental_deposit.date}
                            onChange={handleRentalDepositChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="notes">Notes:</label>
                        <input
                            type="text"
                            id="notes"
                            name="notes"
                            className="form-control"
                            value={rental_deposit.notes}
                            onChange={handleRentalDepositChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="loren_name">Loren Name:</label>
                        <select
                            id="loren_name"
                            name="loren_name"
                            className="form-control text-font"
                            value={rental_deposit.loren_name}
                            onChange={handleRentalDepositChange}
                        >
                            <option value="">Select Loren Name</option>
                            {lorenProperties.map((loren_name, index) => (
                                <option key={index} value={loren_name}>{loren_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="text-center"> { }
                        <button type="submit" className="btn btn-primary">Add Deposit</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddRentalDeposit;
