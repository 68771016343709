import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getToken } from '../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

const AddInvestment = ({ rentalProperties, investors }) => {
    const navigate = useNavigate();
    const [investment, setInvestment] = useState({
        property_name: '',
        amount: '',
        date: '',
        investor_name: '',
    });
    const [setError] = useState(null);

    const handleInvestmentChange = (e) => {
        const { name, value } = e.target;
        setInvestment({
            ...investment,
            [name]: value
        });
    };

    const handleInvestmentSubmit = async (e) => {
        e.preventDefault();

        const { property_name, amount, date, investor_name } = investment;
        if (!property_name || !amount || !date || !investor_name) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const token = getToken(navigate);

            await axios.post(`${apiUrl}/admin/add_investment`,
                investment,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            alert(`New investment added successfully`);

            setInvestment({
                property_name: '',
                amount: '',
                date: '',
                investor_name: '',
            });
        } catch (error) {
            setError('Error adding investment:', error);
        }
    };

    return (
        <Row className='text-font admin-row'>
            <Col md={12}>
                <p className="lead fw-bold text-font">Add New Investment</p>
                <form onSubmit={handleInvestmentSubmit}>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="property_name">Property:</label>
                        <select
                            id="property_name"
                            name="property_name"
                            className="form-control text-font"
                            value={investment.property_name}
                            onChange={handleInvestmentChange}
                            required
                        >
                            <option value="">Select Property</option>
                            {rentalProperties.map((property_name, index) => (
                                <option key={index} value={property_name}>{property_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="investor_name">Investor:</label>
                        <select
                            id="investor_name"
                            name="investor_name"
                            className="form-control text-font"
                            value={investment.investor_name}
                            onChange={handleInvestmentChange}
                            required
                        >
                            <option value="">Select Investor</option>
                            {investors.map((investor_name, index) => (
                                <option key={index} value={investor_name}>{investor_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1" htmlFor="date">Investment Date:</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            className="form-control"
                            value={investment.date}
                            onChange={handleInvestmentChange}
                            required
                        />
                    </div>
                    <div className="form-group mb-5">
                        <label className="mb-1" htmlFor="amount">Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            name="amount"
                            className="form-control"
                            value={investment.amount}
                            onChange={handleInvestmentChange}
                            required
                        />
                    </div>
                    <div className="text-center"> { }
                        <button type="submit" className="btn btn-primary">Add Investment</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

export default AddInvestment;
